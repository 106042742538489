import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/piquetes',
    name: 'piquetes',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/equipas',
    name: 'equipas',
    component: () => import('../views/Equipas.vue'),
  },
  {
    path: '/zonas',
    name: 'zonas',
    component: () => import('../views/Zonas.vue'),
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../views/Servicos.vue'),
  },
  {
    path: '/servicos-remotos',
    name: 'servicos-remotos',
    component: () => import('../views/ServicosRemotos.vue'),
  },
  {
    path: '/provisorios',
    name: 'provisorios',
    component: () => import('../views/Provisorios.vue'),
  },
  {
    path: '/subcontratados',
    name: 'subcontratados',
    component: () => import('../views/ServicosSubcontratados.vue'),
  },
  {
    path: '/planeamento',
    name: 'planeamento',
    component: () => import('../views/Planeamento.vue'),
  },
  {
    path: '/indicadores',
    name: 'indicadores',
    component: () => import('../views/Indicadores.vue'),
  },
  {
    path: '/preventivas',
    name: 'preventivas',
    component: () => import('../views/Preventivas.vue'),
  },
  {
    path: '/estrategico',
    name: 'estrategico',
    component: () => import('../views/Estrategico.vue'),
  },
  {
    path: '/cenarios',
    redirect: '/estrategico'
  },
  {
    path: '/cenarios/:id',
    name: 'cenarios',
    component: () => import('../views/Cenarios.vue'),
  },
  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: () => import('../views/Configuracoes.vue'),
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: () => import('../views/Mapa.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
